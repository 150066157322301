<template>
  <div>
    <div class="row justify-center q-ma-md">
      <q-card class="col-xs-12 col-sm-6 q-ma-sm">
        <q-card-section class="q-pa-none">
          <div class="text-h6 text-center">
            Acordo - {{ data.agreementNumber }}
          </div>
        </q-card-section>
        <q-separator size="1px" color="black" />
        <q-card-section>
          <div class="col-12">
            <span class="text-bold">{{
              `${data.branchCode} - ${data.branchName}`
            }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Repres.: </span>
            <span>{{ data.agent }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Data Acordo: </span>
            <span>{{ format(data.agreementDate, "date") }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Cliente: </span>
            <span>{{ data.custumerCode + " - " + data.custumerName }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Fantasia: </span>
            <span>{{ data.custumerFancy }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Valor do Acordo: </span>
            <span>{{
              `R$ ${parseFloat(data.amountOfAgreement).toLocaleString("pt-BR", {
                minimumFractionDigits: 2
              })}`
            }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Tipo Negociação: </span>
            <span>{{ data.typeNegotiation }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Forma Pagamento: </span>
            <span>{{ data.faymentForm }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Gestor: </span>
            <span>{{ data.manager }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Município: </span>
            <span>{{ data.custumerLocation }}</span>
          </div>
          <div class="col-12">
            <span class="text-bold">Obs.: </span>
            <span>{{ data.obsAgreement }}</span>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
import formatString from "../../helpers/formatString";
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    format(value, type) {
      return formatString(value, type);
    }
  }
};
</script>
