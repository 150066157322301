<template>
  <div class="ApprovalHierarchy">
    <q-dialog v-model="opened" class="q-pa-none">
      <q-card class="q-pa-none q-ma-none">
        <q-card-section class="text-center text-bold">
          <span class="text-h5">Hierarquia de Aprovação</span>
        </q-card-section>
        <q-card-section class="q-pa-none q-ma-none">
          <DataTable
            :columns="columns"
            :data="approvalHierarchy"
            :gridMobile="false"
            dense
            hidePagination
          />
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="Fechar" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  components: {
    DataTable
  },
  data() {
    return {
      opened: false,
      approvalHierarchy: null,
      columns: [
        {
          field: "level",
          label: "Nivel",
          align: "center",
          sortable: true
        },
        {
          field: "approver",
          label: "Aprovador",
          type: "string",
          align: "left",
          sortable: true
        },
        {
          field: "status",
          label: "Status",
          align: "center",
          sortable: true
        },
        {
          field: "approvalDate",
          label: "Dt.Aprovação",
          align: "center",
          type: "date",
          sortable: true
        }
      ]
    };
  },
  methods: {
    open(approvalHierarchy) {
      console.log(approvalHierarchy);
      this.opened = true;
      this.approvalHierarchy = approvalHierarchy;
    }
  }
};
</script>
